import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import * as PropTypes from "prop-types"
import config from "../../../data/SiteConfig"
import "./header-menu.scss"
import "./base.scss"

const propTypes = {
  children: PropTypes.func.isRequired,
}

const DefaultLayout = props => (
  <div>
    <Helmet>
      <title>{config.siteTitle}</title>
      <meta name="og:title" content={config.siteTitle} />
      <meta name="twitter:title" content={config.siteTitle} />
      <meta name="description" content={config.siteDescription} />
      <meta name="og:description" content={config.siteDescription} />
      <meta name="twitter:description" content={config.siteDescription} />
    </Helmet>

    <div className="header-menu-container">
      <div className="header-menu">
        <Link className="logo-link" to="/sv/">
          <img
            className="everysport-header-logo"
            src="/logos/etv/etv2.png"
            alt="Everysport TV"
          />
        </Link>
      </div>
    </div>
    <div>{props.children}</div>
  </div>
)

DefaultLayout.propTypes = propTypes

export default DefaultLayout
