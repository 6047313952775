import React from "react"
import { navigate } from "gatsby"
import BaseLayout from "../components/Layout/layout"

export default class DeployPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      submitted: false,
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ submitted: true })

    const authorizationBasic =
      window &&
      window.btoa(`${this.usernameField.value}:${this.passwordField.value}`)

    const request = new XMLHttpRequest()
    request.open(
      "POST",
      "https://api.bitbucket.org/2.0/repositories/menmo/everysport-tv-web/pipelines/",
      true
    )
    request.setRequestHeader("Content-Type", "application/json; charset=UTF-8")
    request.setRequestHeader("Authorization", `Basic ${authorizationBasic}`)
    request.setRequestHeader("Accept", "application/json")
    request.send(
      JSON.stringify({
        target: {
          selector: {
            type: "custom",
            pattern: "deploy",
          },
          type: "pipeline_ref_target",
          ref_name: "master",
          ref_type: "branch",
        },
      })
    )

    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        setTimeout(() => {
          navigate("/")
        }, 1000)
      }
    }
  }

  render() {
    const formDisplay = this.state.submitted
      ? { display: "none" }
      : { display: "" }
    const messageDisplay = !this.state.submitted
      ? { display: "none" }
      : { display: "" }
    return (
      <BaseLayout>
        <div style={{ padding: 15 }}>
          <form style={formDisplay} onSubmit={this.handleSubmit}>
            <span style={{ marginRight: 15 }}>Username:</span>
            <input
              style={{ width: 200 }}
              name="username"
              type="text"
              ref={c => {
                this.usernameField = c
              }}
            />
            <br />
            <span style={{ marginRight: 15 }}>Password: </span>
            <input
              style={{ width: 200 }}
              name="password"
              type="password"
              ref={c => {
                this.passwordField = c
              }}
            />
            <br />
            <input
              style={{ marginTop: 15, width: 150 }}
              type="submit"
              value="Deploy"
            />
          </form>
          <div style={messageDisplay}>
            Deployment request is sent, content will be updated around 15
            minutes! Redirecting to homepage now..{" "}
          </div>
        </div>
      </BaseLayout>
    )
  }
}
